export const results = {
  '194489809': {
    productName: 'Renew Sensitive +',
    benefits: ['+ Colágeno', '- Sensibilidade'],
    text: [
      'Ter sensibilidade na pele é muito mais comum do que a gente pensa. Todos os tipos e tons de pele, e em todas as idades, estão vulneráveis a sensibilidade.',
      'Quem tem sensibilidade precisa de produtos que sejam hipoalergênicos e de preferência livres de corantes, fragrâncias e ácidos. Além de claro consultar um/uma dermatologistx, combinado?',
      'Eu te indico o Renew Sensitive+, além dele ser livre de tudo que pode sensibilizar a pele, ele também acalma e trata os sinais da idade, como linhas de expressão e rugas.',
    ],
    link: '/creme-renew-sensitive-duplo-colageno-1474078/p?sc=1',
    color: '#C88598',
  },
  '159917658': {
    productName: 'Renew Hydra Pro Vita-D',
    benefits: ['+ Hidratação', '- Ressecamento'],
    text: [
      'Ainda não ter os primeiro sinais da idade, não quer dizer que você não precisa cuidar da pele, viu? A hidratação é essencial para todos os tipos de pele, inclusive as oleosas, sabia? Uma hidratação bem feita ajuda a controlar a oleosidade da pele e deixa a make ainda mais maravilhosa!',
      'Por isso a minha escolha pra você é o Renew Hydra Pro Vita-D.',
      'Ele hidrata por 72 horas, é leve, não deixa a pele oleosa e estimula a ativação da vitamina D, que reforça a barreira de hidratação da pele.',
    ],
    link: '/1401315-gel-creme-renew-hydra-pro-vita-d---50g/p',
    color: '#1A578D',
  },
  '115630261': {
    productName: 'Renew Platinum Dia +55',
    benefits: ['+ Elasticidade', '- Rugas Profundas'],
    text: [
      'Na faixa dos 55 anos a gente já começa a ver rugas mais profundas e a pele começa a perder elasticidade.',
      'Então eu te indico Renew Platinum, que foi desenvolvido para as necessidades da pele madura, pois trata e suaviza até as rugas mais profundas. Reativa a produção de elastina em 48h* e por isso ajuda a levantar e firmar a pele flácida.',
    ],
    link: '/1378273-creme-renew-platinum-noite-elasticidade-55----50g/p',
    color: '#51477B',
  },
  '115630265': {
    productName: 'Renew Platinum Dia +55',
    benefits: ['+ Elasticidade', '- Rugas Profundas'],
    text: [
      'Na faixa dos 55 anos a gente já começa a ver rugas mais profundas e a pele começa a perder elasticidade.',
      'Então eu te indico Renew Platinum, que foi desenvolvido para as necessidades da pele madura, pois trata e suaviza até as rugas mais profundas. Reativa a produção de elastina em 48h* e por isso ajuda a levantar e firmar a pele flácida.',
    ],
    link: '1376790-creme-renew-platinum-dia-elasticidade-55-/p',
    color: '#51477B',
  },
  '115630257': {
    productName: 'Renew Ultimate Dia +45',
    benefits: ['+ Firmeza', '- Rugas'],
    text: [
      'Na faixa dos 45 anos a gente já começa a ver rugas e a pele começa a perder firmeza.',
      'E eu tenho o creme perfeito para você, o Renew Ultimate, pois ele trará e suavizará rugas, ao mesmo tempo que interrompe imediatamente a perda de colágeno e restaura a sua produção em 48h*',
    ],
    link: '/1378274-creme-renew-ultimate-dia-restauracao-45/p?sc=1',
    color: '#947A57',
  },
  '115630253': {
    productName: 'Renew Ultimate Dia +45',
    benefits: ['+ Firmeza', '- Rugas'],
    text: [
      'Na faixa dos 45 anos a gente já começa a ver rugas e a pele começa a perder firmeza.',
      'E eu tenho o creme perfeito para você, o Renew Ultimate, pois ele trará e suavizará rugas, ao mesmo tempo que interrompe imediatamente a perda de colágeno e restaura a sua produção em 48h*',
    ],
    link: '/1378274-creme-renew-ultimate-dia-restauracao-45/p?sc=1',
    color: '#947A57',
  },
  '194489389': {
    productName: 'Renew Reversalist Dia +30',
    benefits: ['+ Renovação', '- Linhas de Expressão'],
    text: [
      'Na faixa dos 30 anos é quando começam a aparecer os primeiro sinais e linhas de expressão.',
      'Por isso a minha escolha pra você é o Renew Reversalist, ele trata as linhas de expressão, revitaliza e hidrata a pele.',
      'Além de reativar a produção de ácido hialurônico em até 100% em 48 horas*',
    ],
    link: '/creme-dia-renew-reversalist-fps25-50g-1478132/p',
    color: '#9B1F1C',
  },
  '115630277': {
    productName: 'Renew Reversalist Dia +30',
    benefits: ['+ Renovação', '- Linhas de Expressão'],
    text: [
      'Na faixa dos 30 anos é quando começam a aparecer os primeiro sinais e linhas de expressão.',
      'Por isso a minha escolha pra você é o Renew Reversalist, ele trata as linhas de expressão, revitaliza e hidrata a pele.',
      'Além de reativar a produção de ácido hialurônico em até 100% em 48 horas*',
    ],
    link: '1378272-creme-renew-reversalist-noite-renovacao-30----50g/p',
    color: '#9B1F1C',
  },
}
