import React, { useState } from 'react'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import { Flex } from '@vtex/store-ui'

import { questions } from './questions'
import { First } from './Steps/First'
import Second from './Steps/Second'
import Third from './Steps/Third'
import Fourth from './Steps/Fourth'
import Fifth from './Steps/Fifth'
import Sixth from './Steps/Sixth'
import Result from './Result'

interface Props {
  step: number
  setStep: (step: number) => void
  data: []
}

const Questions = ({ step, setStep, data }: Props) => {
  const { setIsLoginModalOpen } = useLoginModal()
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const [responses, setResponses] = useState<{
    faixaEtaria: string
    alergias: string
    coceira: string
    irritacao: string
    peleVermelha: string
  }>({
    faixaEtaria: '',
    alergias: '',
    coceira: '',
    irritacao: '',
    peleVermelha: '',
  })

  const nextQuestion = (newResponse: {
    faixaEtaria?: string
    alergias?: string
    coceira?: string
    irritacao?: string
    peleVermelha?: string
  }) => {
    setResponses({ ...responses, ...newResponse })

    setStep(step + 1)
  }

  if (!isAuthenticated) {
    setIsLoginModalOpen(true)
  }

  const switchQuestion = () => {
    switch (step) {
      case 0:
        return (
          <First
            image={data[0].props}
            question={questions[step]}
            nextQuestion={nextQuestion}
          />
        )

      case 1:
        return (
          <Second
            image={data[1].props}
            question={questions[step]}
            nextQuestion={nextQuestion}
          />
        )

      case 2:
        return <Third question={questions[step]} nextQuestion={nextQuestion} />

      case 3:
        return <Fourth question={questions[step]} nextQuestion={nextQuestion} />

      case 4:
        return <Fifth question={questions[step]} nextQuestion={nextQuestion} />

      case 5:
        return <Sixth question={questions[step]} nextQuestion={nextQuestion} />

      case 6:
        return <Result result={responses} data={data} />

      default:
        return <></>
    }
  }

  return (
    <Flex
      sx={{
        padding: step === 6 ? '0' : '0.5rem 1rem 0',
        minHeight: 'calc(100vh - 94px)',
        fontFamily: 'Zona Pro, sans-serif',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: step === 6 ? ['40px', '65px'] : '90px',
          left: '0',
          width: `calc(14.29% * ${step + 1})`,
          height: '3px',
          backgroundColor: '#C88598',
        },
      }}
    >
      {switchQuestion()}
    </Flex>
  )
}

export default Questions
