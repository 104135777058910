import React from 'react'
import { Flex, Grid, Heading, Text } from '@vtex/store-ui'

import styles from './styles.json'
import type { QuestionsType } from '../typings'
import { FourthStepIcon } from './icon'

const Fourth = ({ question, nextQuestion }: QuestionsType) => {
  const handleClick = (selectedOption: string) => {
    switch (selectedOption) {
      case 'Sim':
        nextQuestion({ irritacao: '1' })
        break

      case 'Não':
        nextQuestion({ irritacao: '2' })
        break

      default:
        break
    }
  }

  return (
    <Grid sx={styles}>
      <Flex sx={styles.question}>
        <Text as="p" sx={styles.step}>
          Passo {question.step} de 7
        </Text>
        <Heading>{question.question}</Heading>
        <Flex sx={styles.options}>
          {question.options?.map((option) => (
            <Flex
              key={option.option}
              onClick={() => handleClick(option.option)}
            >
              {option.option}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex sx={styles.icon}>
        <FourthStepIcon />
      </Flex>
    </Grid>
  )
}

export default Fourth
