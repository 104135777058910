import React from 'react'
import type { FC } from 'react'

export const ThirdStepIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="339.62"
    height="508.385"
    viewBox="0 0 339.62 508.385"
  >
    <g
      id="Group_9030"
      data-name="Group 9030"
      transform="translate(-150.513 -148.999)"
    >
      <g id="noun-blackhead-4713659" transform="translate(143.884 219.336)">
        <path
          id="Path_9"
          data-name="Path 9"
          d="M25.913,205.854h-.446a5.579,5.579,0,0,1-5.077-5.579c4.407-58.857,31.8-102.819,73.195-117.6a251.049,251.049,0,0,0,71.744-38.606,120.224,120.224,0,0,0,20.809-20.2,5.579,5.579,0,1,1,8.982,6.416,123.294,123.294,0,0,1-22.316,22.037,260.869,260.869,0,0,1-75.371,40.5C60.167,106.047,35.509,146.439,31.38,200.833A5.579,5.579,0,0,1,25.913,205.854Z"
          transform="translate(26.377 43.071)"
          fill="#e9beb7"
        />
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M8501.5-12210.973h0a151,151,0,0,0-31.384-3.329,148.665,148.665,0,0,0-30.67,3.207,47.934,47.934,0,0,1-6.8-7.268,47.612,47.612,0,0,1-5.167-8.574,47.071,47.071,0,0,1-4.434-20.063,47.2,47.2,0,0,1,3.732-18.488,47.355,47.355,0,0,1,10.18-15.1,47.345,47.345,0,0,1,15.1-10.18,47.18,47.18,0,0,1,18.488-3.733,47.181,47.181,0,0,1,18.489,3.733,47.355,47.355,0,0,1,15.1,10.18,47.355,47.355,0,0,1,10.18,15.1,47.2,47.2,0,0,1,3.732,18.488,47.074,47.074,0,0,1-4.474,20.149,47.632,47.632,0,0,1-5.212,8.6,47.979,47.979,0,0,1-6.857,7.277Z"
          transform="translate(-8292.427 12229.664)"
          fill="none"
          stroke="#e9beb7"
          strokeWidth="11"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M159.818,169.139a5.579,5.579,0,0,1-5.579-4.519,170.659,170.659,0,0,0-23.878-60.866A164.466,164.466,0,0,0,95.772,65.372,157.046,157.046,0,0,0,50.527,39.486a5.579,5.579,0,0,1,3.347-10.544,165.582,165.582,0,0,1,48.425,27.5,173.783,173.783,0,0,1,37.323,41.061,181.2,181.2,0,0,1,25.83,65.161,5.579,5.579,0,0,1-4.575,6.471Z"
          transform="translate(149.659 79.785)"
          fill="#e9beb7"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M83.016,63.94S73.2,50.551,33.81,53.786a5.6,5.6,0,0,1-.893-11.158c46.3-3.794,58.244,13.334,59.415,15.286L82.9,63.494Z"
          transform="translate(55.193 146.152)"
          fill="#e9beb7"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M48.7,55.06,37.932,52.55h0a14.059,14.059,0,0,1-6.639,6.806,5.579,5.579,0,1,0,5.579,9.6A23.487,23.487,0,0,0,48.7,55.06Z"
          transform="translate(55.121 190.285)"
          fill="#e9beb7"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M48.7,55.06,37.932,52.55h0a14.059,14.059,0,0,1-6.639,6.806,5.579,5.579,0,1,0,5.579,9.6A23.487,23.487,0,0,0,48.7,55.06Z"
          transform="translate(38.121 175.285)"
          fill="#e9beb7"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M56.49,55.06,67.257,52.55h0a14.059,14.059,0,0,0,6.583,6.416,5.579,5.579,0,0,1-5.579,9.6A23.487,23.487,0,0,1,56.49,55.06Z"
          transform="translate(191.675 188.285)"
          fill="#e9beb7"
        />
        <path
          id="Path_60"
          data-name="Path 60"
          d="M-10951.924,12703.355s5.465-2.9,8.3-.152c.7.7-.537,2.639-.537,2.639s-3.837.437-4.1.349-3.532-1-3.663-1.047A8.166,8.166,0,0,1-10951.924,12703.355Z"
          transform="translate(11212.116 -12477.336)"
          fill="#e9beb7"
        />
        <path
          id="Path_59"
          data-name="Path 59"
          d="M56.49,55.06,67.257,52.55h0a14.059,14.059,0,0,0,6.583,6.416,5.579,5.579,0,0,1-5.579,9.6C65.654,67.383,59.934,51.5,59.934,51.5"
          transform="translate(191.126 189.761) rotate(-13)"
          fill="#e9beb7"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M154.627,217.642c-17.852,0-46.7-18.02-79.109-49.373a197.771,197.771,0,0,1-43.683-66.947A211.552,211.552,0,0,1,20.12,65.505a5.579,5.579,0,0,1,10.823-2.232A212,212,0,0,0,42.1,97.305a191.3,191.3,0,0,0,41.116,62.762c23.1,22.316,54,46.249,71.41,46.249a5.579,5.579,0,1,1,0,11.158Z"
          transform="translate(25.086 220.407)"
          fill="#e9beb7"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M73.274,98.025a82.4,82.4,0,0,1-27.894-3.4c-6.025-2.343-7.253-5.579-7.476-7.531a5.579,5.579,0,0,1,1.116-3.961A49.484,49.484,0,0,1,55.757,70.688,21.2,21.2,0,0,1,64.85,68.68a5.579,5.579,0,1,1,0,11.158,10.1,10.1,0,0,0-4.3.948,54.28,54.28,0,0,0-8.089,4.686A101.257,101.257,0,0,0,73.051,87.09a5.579,5.579,0,1,1,0,11.158Z"
          transform="translate(100.006 264.985)"
          fill="#e9beb7"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M69.242,95.176A45.635,45.635,0,0,1,41.626,85.022a5.579,5.579,0,1,1,7.308-8.034,34.533,34.533,0,0,0,20.307,7.42,5.579,5.579,0,0,1,0,11.158Z"
          transform="translate(101.47 292.702)"
          fill="#e9beb7"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M64.477,87.139h-.335C50.976,86.247,42.106,72.132,41.1,70.4a5.579,5.579,0,1,1,9.484-5.579c1.841,2.957,7.978,10.823,14.282,11.158a5.582,5.582,0,1,1-.391,11.158Z"
          transform="translate(109.429 231.369)"
          fill="#e9beb7"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          d="M61.328,141.031A41.228,41.228,0,0,1,31.37,125.41,65.217,65.217,0,0,1,15.3,91.937a37.49,37.49,0,0,1,8.591-31.744A29.066,29.066,0,0,1,36.335,52.55l2.789,10.711h0a18.3,18.3,0,0,0-7.7,5.021A26.444,26.444,0,0,0,26.181,90.6a55.008,55.008,0,0,0,13.334,27.894,29.959,29.959,0,0,0,23.32,11.827,5.6,5.6,0,0,1,.948,11.158Z"
          transform="translate(0 188.285)"
          fill="#e9beb7"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          d="M58.278,63.574h0L48.85,57.995c1.172-1.953,13.055-19.08,59.415-15.286a5.6,5.6,0,0,1-.948,11.158C68.1,50.129,58.39,63.574,58.278,63.574Z"
          transform="translate(154.518 144.326)"
          fill="#e9beb7"
        />
        <path
          id="Path_22"
          data-name="Path 22"
          d="M48.579,117.828a5.579,5.579,0,1,1,0-11.158c4.742,0,18.689-2.343,45.747-23.6a5.579,5.579,0,1,1,6.806,8.7C79.765,108.623,61.076,117.828,48.579,117.828Z"
          transform="translate(129.906 320.221)"
          fill="#e9beb7"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M48.579,98.208a5.579,5.579,0,0,1,0-11.158,101.26,101.26,0,0,0,20.586-1.618,50.88,50.88,0,0,0-8.034-4.686,10.265,10.265,0,0,0-4.3-.948,5.579,5.579,0,1,1,0-11.158,21.424,21.424,0,0,1,9.094,2.008A49.485,49.485,0,0,1,82.666,83.089a5.579,5.579,0,0,1,1.116,3.961c0,1.841-1.45,5.188-7.476,7.531A82.569,82.569,0,0,1,48.579,98.208Z"
          transform="translate(127.259 264.959)"
          fill="#e9beb7"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M48.579,94.452a5.579,5.579,0,0,1,0-11.158,34.31,34.31,0,0,0,20.2-7.029,5.321,5.321,0,1,1,7.2,7.81A45.245,45.245,0,0,1,48.579,94.452Z"
          transform="translate(125.534 293.816)"
          fill="#e9beb7"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M48.718,87.232a5.581,5.581,0,1,1-.335-11.158c6.3-.446,12.441-8.313,14.282-11.158A5.579,5.579,0,1,1,72.094,70.5c-.948,1.618-9.819,15.732-22.985,16.737Z"
          transform="translate(127.29 231.276)"
          fill="#e9beb7"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M28.8,251.546a5.579,5.579,0,0,1-5.579-4.519,427.837,427.837,0,0,1-5.579-43.85c-2.455-33.473-.558-60.754,5.579-81.508a163.908,163.908,0,0,1,45.8-70.294,153.029,153.029,0,0,1,43.738-28.4,148.621,148.621,0,0,1,58.076-11.548,5.579,5.579,0,0,1,0,11.158,137.073,137.073,0,0,0-53.78,10.656A141.871,141.871,0,0,0,76.5,59.577,150.63,150.63,0,0,0,34.269,124.85c-13.78,46.639,0,119.444,0,120.169a5.579,5.579,0,0,1-4.3,6.416Z"
          transform="translate(9.039 0)"
          fill="#e9beb7"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M190.742,251.5h-1.06a5.579,5.579,0,0,1-4.352-6.472h0c0-.725,13.836-73.53,0-120.169a151.021,151.021,0,0,0-42.288-65.273,140.364,140.364,0,0,0-40.559-26.332A137.017,137.017,0,0,0,48.759,22.6a5.579,5.579,0,1,1,0-11.158,148.622,148.622,0,0,1,58.188,11.492,152.75,152.75,0,0,1,43.683,28.4,163.517,163.517,0,0,1,45.412,70.294c6.137,20.753,7.978,48.2,5.579,81.507a401.16,401.16,0,0,1-5.579,43.85A5.579,5.579,0,0,1,190.742,251.5Z"
          transform="translate(130.73 0.045)"
          fill="#e9beb7"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M25.687,217.641c17.852,0,46.7-18.02,79.109-49.373a197.771,197.771,0,0,0,43.683-66.946,211.553,211.553,0,0,0,11.716-35.816,5.579,5.579,0,0,0-10.823-2.232,212,212,0,0,1-11.158,34.031A191.3,191.3,0,0,1,97.1,160.067c-23.1,22.316-54,46.249-71.41,46.249a5.579,5.579,0,1,0,0,11.158Z"
          transform="translate(152.629 220.407)"
          fill="#e9beb7"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M22.209,141.031A41.228,41.228,0,0,0,52.168,125.41,65.217,65.217,0,0,0,68.236,91.937a37.49,37.49,0,0,0-8.592-31.744A29.066,29.066,0,0,0,47.2,52.55L44.413,63.261h0a18.3,18.3,0,0,1,7.7,5.021A26.444,26.444,0,0,1,57.357,90.6a55.008,55.008,0,0,1-13.334,27.895A29.959,29.959,0,0,1,20.7,130.32a5.6,5.6,0,1,0-.948,11.158Z"
          transform="translate(277.341 188.285)"
          fill="#e9beb7"
        />
      </g>
      <g
        id="noun-blackhead-4713659-2"
        data-name="noun-blackhead-4713659"
        transform="translate(135.884 219.336)"
      >
        <path
          id="Path_9-2"
          data-name="Path 9"
          d="M25.913,205.854h-.446a5.579,5.579,0,0,1-5.077-5.579c4.407-58.857,31.8-102.819,73.195-117.6a251.049,251.049,0,0,0,71.744-38.606,120.224,120.224,0,0,0,20.809-20.2,5.579,5.579,0,1,1,8.982,6.416,123.294,123.294,0,0,1-22.316,22.037,260.869,260.869,0,0,1-75.371,40.5C60.167,106.047,35.509,146.439,31.38,200.833A5.579,5.579,0,0,1,25.913,205.854Z"
          transform="translate(26.377 43.071)"
        />
        <path
          id="Path_10-2"
          data-name="Path 10"
          d="M159.818,169.139a5.579,5.579,0,0,1-5.579-4.519,170.659,170.659,0,0,0-23.878-60.866A164.466,164.466,0,0,0,95.772,65.372,157.046,157.046,0,0,0,50.527,39.486a5.579,5.579,0,0,1,3.347-10.544,165.582,165.582,0,0,1,48.425,27.5,173.783,173.783,0,0,1,37.323,41.061,181.2,181.2,0,0,1,25.83,65.161,5.579,5.579,0,0,1-4.575,6.471Z"
          transform="translate(149.659 79.785)"
        />
        <path
          id="Path_11-2"
          data-name="Path 11"
          d="M83.016,63.94S73.2,50.551,33.81,53.786a5.6,5.6,0,0,1-.893-11.158c46.3-3.794,58.244,13.334,59.415,15.286L82.9,63.494Z"
          transform="translate(60.155 140.353)"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M73.573,72.454A57.407,57.407,0,0,1,40.1,63.193a22.316,22.316,0,0,1-8.48,5.077,5.579,5.579,0,0,1-3.459-10.488A11.158,11.158,0,0,0,34.186,53.1,5.4,5.4,0,0,1,43,51.31c18.968,17.8,54.729,6.472,55.064,6.36a5.579,5.579,0,0,1,3.4,10.544,99.473,99.473,0,0,1-27.894,4.24Z"
          transform="translate(47.784 175.466)"
        />
        <path
          id="Path_13-2"
          data-name="Path 13"
          d="M48.7,55.06,37.932,52.55h0a14.059,14.059,0,0,1-6.639,6.806,5.579,5.579,0,1,0,5.579,9.6A23.487,23.487,0,0,0,48.7,55.06Z"
          transform="translate(61.277 188.285)"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M80.28,72.454a57.128,57.128,0,0,0,33.194-9.261,22.929,22.929,0,0,0,8.536,5.077,5.579,5.579,0,0,0,3.459-10.488,10.656,10.656,0,0,1-6.025-4.686,5.4,5.4,0,0,0-8.815-1.785c-18.968,17.8-54.729,6.472-55.064,6.36a5.579,5.579,0,0,0-3.459,10.544,100.421,100.421,0,0,0,28.173,4.24Z"
          transform="translate(156.504 175.466)"
        />
        <path
          id="Path_15-2"
          data-name="Path 15"
          d="M56.49,55.06,67.257,52.55h0a14.059,14.059,0,0,0,6.583,6.416,5.579,5.579,0,0,1-5.579,9.6A23.487,23.487,0,0,1,56.49,55.06Z"
          transform="translate(191.675 188.285)"
        />
        <path
          id="Path_16-2"
          data-name="Path 16"
          d="M154.627,217.642c-17.852,0-46.7-18.02-79.109-49.373a197.771,197.771,0,0,1-43.683-66.947A211.552,211.552,0,0,1,20.12,65.505a5.579,5.579,0,0,1,10.823-2.232A212,212,0,0,0,42.1,97.305a191.3,191.3,0,0,0,41.116,62.762c23.1,22.316,54,46.249,71.41,46.249a5.579,5.579,0,1,1,0,11.158Z"
          transform="translate(25.086 220.407)"
        />
        <path
          id="Path_17-2"
          data-name="Path 17"
          d="M73.274,98.025a82.4,82.4,0,0,1-27.894-3.4c-6.025-2.343-7.253-5.579-7.476-7.531a5.579,5.579,0,0,1,1.116-3.961A49.484,49.484,0,0,1,55.757,70.688,21.2,21.2,0,0,1,64.85,68.68a5.579,5.579,0,1,1,0,11.158,10.1,10.1,0,0,0-4.3.948,54.28,54.28,0,0,0-8.089,4.686A101.257,101.257,0,0,0,73.051,87.09a5.579,5.579,0,1,1,0,11.158Z"
          transform="translate(106.438 262.142)"
        />
        <path
          id="Path_18-2"
          data-name="Path 18"
          d="M69.242,95.176A45.635,45.635,0,0,1,41.626,85.022a5.579,5.579,0,1,1,7.308-8.034,34.533,34.533,0,0,0,20.307,7.42,5.579,5.579,0,0,1,0,11.158Z"
          transform="translate(110.47 289.092)"
        />
        <path
          id="Path_19-2"
          data-name="Path 19"
          d="M64.477,87.139h-.335C50.976,86.247,42.106,72.132,41.1,70.4a5.579,5.579,0,1,1,9.484-5.579c1.841,2.957,7.978,10.823,14.282,11.158a5.582,5.582,0,1,1-.391,11.158Z"
          transform="translate(115.235 228.062)"
        />
        <path
          id="Path_20-2"
          data-name="Path 20"
          d="M61.328,141.031A41.228,41.228,0,0,1,31.37,125.41,65.217,65.217,0,0,1,15.3,91.937a37.49,37.49,0,0,1,8.591-31.744A29.066,29.066,0,0,1,36.335,52.55l2.789,10.711h0a18.3,18.3,0,0,0-7.7,5.021A26.444,26.444,0,0,0,26.181,90.6a55.008,55.008,0,0,0,13.334,27.894,29.959,29.959,0,0,0,23.32,11.827,5.6,5.6,0,0,1,.948,11.158Z"
          transform="translate(0 188.285)"
        />
        <path
          id="Path_21-2"
          data-name="Path 21"
          d="M58.278,63.574h0L48.85,57.995c1.172-1.953,13.055-19.08,59.415-15.286a5.6,5.6,0,0,1-.948,11.158C68.1,50.129,58.39,63.574,58.278,63.574Z"
          transform="translate(156.692 140.719)"
        />
        <path
          id="Path_22-2"
          data-name="Path 22"
          d="M48.579,117.828a5.579,5.579,0,1,1,0-11.158c4.742,0,18.689-2.343,45.747-23.6a5.579,5.579,0,1,1,6.806,8.7C79.765,108.623,61.076,117.828,48.579,117.828Z"
          transform="translate(129.906 320.221)"
        />
        <path
          id="Path_23-2"
          data-name="Path 23"
          d="M48.579,98.208a5.579,5.579,0,0,1,0-11.158,101.26,101.26,0,0,0,20.586-1.618,50.88,50.88,0,0,0-8.034-4.686,10.265,10.265,0,0,0-4.3-.948,5.579,5.579,0,1,1,0-11.158,21.424,21.424,0,0,1,9.094,2.008A49.485,49.485,0,0,1,82.666,83.089a5.579,5.579,0,0,1,1.116,3.961c0,1.841-1.45,5.188-7.476,7.531A82.569,82.569,0,0,1,48.579,98.208Z"
          transform="translate(129.906 261.959)"
        />
        <path
          id="Path_24-2"
          data-name="Path 24"
          d="M48.579,94.452a5.579,5.579,0,0,1,0-11.158,34.31,34.31,0,0,0,20.2-7.029,5.321,5.321,0,1,1,7.2,7.81A45.245,45.245,0,0,1,48.579,94.452Z"
          transform="translate(129.906 289.816)"
        />
        <path
          id="Path_25-2"
          data-name="Path 25"
          d="M48.718,87.232a5.581,5.581,0,1,1-.335-11.158c6.3-.446,12.441-8.313,14.282-11.158A5.579,5.579,0,1,1,72.094,70.5c-.948,1.618-9.819,15.732-22.985,16.737Z"
          transform="translate(129.766 227.969)"
        />
        <path
          id="Path_26-2"
          data-name="Path 26"
          d="M28.8,251.546a5.579,5.579,0,0,1-5.579-4.519,427.837,427.837,0,0,1-5.579-43.85c-2.455-33.473-.558-60.754,5.579-81.508a163.908,163.908,0,0,1,45.8-70.294,153.029,153.029,0,0,1,43.738-28.4,148.621,148.621,0,0,1,58.076-11.548,5.579,5.579,0,0,1,0,11.158,137.073,137.073,0,0,0-53.78,10.656A141.871,141.871,0,0,0,76.5,59.577,150.63,150.63,0,0,0,34.269,124.85c-13.78,46.639,0,119.444,0,120.169a5.579,5.579,0,0,1-4.3,6.416Z"
          transform="translate(9.039 0)"
        />
        <path
          id="Path_27-2"
          data-name="Path 27"
          d="M190.742,251.5h-1.06a5.579,5.579,0,0,1-4.352-6.472h0c0-.725,13.836-73.53,0-120.169a151.021,151.021,0,0,0-42.288-65.273,140.364,140.364,0,0,0-40.559-26.332A137.017,137.017,0,0,0,48.759,22.6a5.579,5.579,0,1,1,0-11.158,148.622,148.622,0,0,1,58.188,11.492,152.75,152.75,0,0,1,43.683,28.4,163.517,163.517,0,0,1,45.412,70.294c6.137,20.753,7.978,48.2,5.579,81.507a401.16,401.16,0,0,1-5.579,43.85A5.579,5.579,0,0,1,190.742,251.5Z"
          transform="translate(130.73 0.045)"
        />
        <path
          id="Path_39-2"
          data-name="Path 39"
          d="M25.687,217.641c17.852,0,46.7-18.02,79.109-49.373a197.771,197.771,0,0,0,43.683-66.946,211.553,211.553,0,0,0,11.716-35.816,5.579,5.579,0,0,0-10.823-2.232,212,212,0,0,1-11.158,34.031A191.3,191.3,0,0,1,97.1,160.067c-23.1,22.316-54,46.249-71.41,46.249a5.579,5.579,0,1,0,0,11.158Z"
          transform="translate(152.629 220.407)"
        />
        <path
          id="Path_40-2"
          data-name="Path 40"
          d="M22.209,141.031A41.228,41.228,0,0,0,52.168,125.41,65.217,65.217,0,0,0,68.236,91.937a37.49,37.49,0,0,0-8.592-31.744A29.066,29.066,0,0,0,47.2,52.55L44.413,63.261h0a18.3,18.3,0,0,1,7.7,5.021A26.444,26.444,0,0,1,57.357,90.6a55.008,55.008,0,0,1-13.334,27.895A29.959,29.959,0,0,1,20.7,130.32a5.6,5.6,0,1,0-.948,11.158Z"
          transform="translate(277.341 188.285)"
        />
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M8501.5-12210.973h0a151,151,0,0,0-31.384-3.329,148.665,148.665,0,0,0-30.67,3.207,47.934,47.934,0,0,1-6.8-7.268,47.612,47.612,0,0,1-5.167-8.574,47.071,47.071,0,0,1-4.434-20.063,47.2,47.2,0,0,1,3.732-18.488,47.355,47.355,0,0,1,10.18-15.1,47.345,47.345,0,0,1,15.1-10.18,47.18,47.18,0,0,1,18.488-3.733,47.181,47.181,0,0,1,18.489,3.733,47.355,47.355,0,0,1,15.1,10.18,47.355,47.355,0,0,1,10.18,15.1,47.2,47.2,0,0,1,3.732,18.488,47.074,47.074,0,0,1-4.474,20.149,47.632,47.632,0,0,1-5.212,8.6,47.979,47.979,0,0,1-6.857,7.277Z"
          transform="translate(-8290.427 12229.664)"
          fill="none"
          stroke="#000"
          strokeWidth="11"
        />
        <circle
          id="Ellipse_167"
          data-name="Ellipse 167"
          cx="36.5"
          cy="36.5"
          r="36.5"
          transform="translate(76.116 268.898)"
          fill="#f75455"
        />
        <circle
          id="Ellipse_168"
          data-name="Ellipse 168"
          cx="36.5"
          cy="36.5"
          r="36.5"
          transform="translate(215.116 268.898)"
          fill="#f75455"
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="M36.108,76.581a5.579,5.579,0,0,1-4.017-9.317h0a5.579,5.579,0,0,1,8.034,7.587h0A5.579,5.579,0,0,1,36.108,76.581Z"
          transform="translate(76.366 249.292)"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M36.108,76.581a5.579,5.579,0,0,1-4.017-9.317h0a5.579,5.579,0,0,1,8.034,7.587h0A5.579,5.579,0,0,1,36.108,76.581Z"
          transform="translate(215.366 249.292)"
        />
        <path
          id="Path_68"
          data-name="Path 68"
          d="M39.227,72.859a5.554,5.554,0,0,1-4.463-8.87h0a5.518,5.518,0,1,1,8.815,6.639h0A5.579,5.579,0,0,1,39.227,72.859Z"
          transform="translate(90.206 229.917)"
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M39.227,72.859a5.554,5.554,0,0,1-4.463-8.87h0a5.518,5.518,0,1,1,8.815,6.639h0A5.579,5.579,0,0,1,39.227,72.859Z"
          transform="translate(229.206 229.917)"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M33.274,72.157a5.523,5.523,0,0,1-3.905-9.428h0a5.543,5.543,0,0,1,7.81,7.866h0A5.579,5.579,0,0,1,33.274,72.157Z"
          transform="translate(63.188 226.825)"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M33.274,72.157a5.523,5.523,0,0,1-3.905-9.428h0a5.543,5.543,0,0,1,7.81,7.866h0A5.579,5.579,0,0,1,33.274,72.157Z"
          transform="translate(202.188 226.825)"
        />
      </g>
    </g>
  </svg>
)
