import React, { useEffect, useState } from 'react'
import { Flex, Heading, Text } from '@vtex/store-ui'
import { ArrowLeft } from 'src/components/icons'

import styles from './styles.json'

interface Props {
  step: number
  setStep: (step: number) => void
}

const Header = ({ step, setStep }: Props) => {
  const [backText, setBackText] = useState<string>('')

  const handleClick = () => {
    if (step === 0) {
      window.location.href = '/'
    } else {
      setStep(step - 1)
    }
  }

  useEffect(() => {
    const text = step === 0 ? 'Ir para Home' : 'Voltar'

    setBackText(text)
  }, [step])

  return (
    <Flex sx={styles}>
      {step !== 6 && (
        <Text as="p" onClick={handleClick}>
          <ArrowLeft /> {backText}
        </Text>
      )}
      <Heading>Encontre o Renew antissinais ideal para sua pele</Heading>
    </Flex>
  )
}

export default Header
