import React from 'react'
import type { FC } from 'react'

export const SixthStepIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="358.674"
    height="438.413"
    viewBox="0 0 358.674 438.413"
  >
    <g
      id="Group_9032"
      data-name="Group 9032"
      transform="translate(-861.519 -177.586)"
    >
      <g id="noun-skin-3858726" transform="translate(870.829 177.586)">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M78.813,34.07a6.851,6.851,0,1,0-1.92,13.567c25.474,3.6,70.205,18.38,83.323,76.928a6.934,6.934,0,0,0,8.195,5.171,6.83,6.83,0,0,0,5.181-8.178C158.106,52.48,102.14,37.361,78.813,34.07Z"
          transform="translate(122.49 79.354)"
          fill="#e9beb7"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M108.609,76.149a6.875,6.875,0,0,0-2.957-9.235,72.646,72.646,0,0,0-28.987-9.566c-24.685-3.041-40.386,15-41.038,15.761a6.871,6.871,0,0,0,.773,9.67,6.985,6.985,0,0,0,9.68-.769A35.124,35.124,0,0,1,75.016,70.944a60.308,60.308,0,0,1,24.351,8.135,6.869,6.869,0,0,0,9.242-2.93Z"
          transform="translate(30.673 143.411)"
          fill="#e9beb7"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M67.414,69.007H46.847a6.852,6.852,0,1,0,0,13.7H67.414a6.852,6.852,0,1,0,0-13.7Z"
          transform="translate(45.617 169.418)"
          fill="#e9beb7"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M104.692,57.271a72.616,72.616,0,0,0-28.986,9.566A6.848,6.848,0,1,0,82,79a60.231,60.231,0,0,1,24.334-8.135A34.969,34.969,0,0,1,135.3,81.956a6.939,6.939,0,0,0,9.643.746,6.869,6.869,0,0,0,.789-9.67,49.05,49.05,0,0,0-41.045-15.761Z"
          transform="translate(123.882 142.348)"
          fill="#e9beb7"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M105.406,69.007H84.851a6.852,6.852,0,0,0,0,13.7h20.554a6.852,6.852,0,1,0,0-13.7Z"
          transform="translate(137.041 169.418)"
          fill="#e9beb7"
        />
        <path
          id="Path_77"
          data-name="Path 77"
          d="M115.654,98.006h-54.8a6.855,6.855,0,0,0,0,13.71h54.8a6.855,6.855,0,0,0,0-13.71Z"
          transform="translate(86.423 237.676)"
          fill="#e9beb7"
        />
        <path
          id="Path_78"
          data-name="Path 78"
          d="M66.851,103.711H80.545a6.852,6.852,0,1,0,0-13.7H66.851a6.852,6.852,0,0,0,0,13.7Z"
          transform="translate(100.981 218.277)"
          fill="#e9beb7"
        />
        <path
          id="Path_79"
          data-name="Path 79"
          d="M80.544,106.007H66.85a6.85,6.85,0,0,0,0,13.7H80.544a6.85,6.85,0,1,0,0-13.7Z"
          transform="translate(100.982 257.08)"
          fill="#e9beb7"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M341.022,231.393a328.512,328.512,0,0,0,3.556-71.64C338.721,82.832,291.709,3.642,199.933.15c-5.352-.194-18.55-.194-23.611-.033C83.98,3.1,36.671,82.45,30.784,159.753A328.576,328.576,0,0,0,34.333,231.4,33.948,33.948,0,0,0,13,263.494,58.146,58.146,0,0,0,56.2,316.449c23.782,63.241,64.248,118.3,121.679,121.9,1.4.084,18.249.084,19.878-.013,57.294-3.763,97.653-58.749,121.4-121.89,32.2-8.409,42.483-37.379,43.205-53.213A33.792,33.792,0,0,0,341.022,231.393ZM44.449,160.8C49.891,89.588,92.916,16.513,176.772,13.81c7.529-.234,15.132-.247,22.661.03a125.212,125.212,0,0,1,59.14,16.724,89.748,89.748,0,0,1-16.638,33.01,124.292,124.292,0,0,1-55.548,38.061,271.663,271.663,0,0,1-26.568,7.185,251.9,251.9,0,0,0-31.679,8.917c-29.9,11.309-60.712,27.849-83.19,71.881-.284.569-.883.151-.91-.582a256.275,256.275,0,0,1,.408-28.237ZM26.671,263.105a19.425,19.425,0,0,1,10.008-17.587c1.017,5.546,2.033,10.51,2.963,14.664,2.863,13.152,6.456,26.461,10.7,39.58C28.454,288.63,26.742,264.142,26.671,263.105ZM196.838,424.662c-.562.03-17.6.037-18.1.013-66.566-4.175-108.607-89.22-125.73-167.42-2.649-12.041-4.689-24.013-6.191-35.673l.024-.044s6.559-18.35,10.305-25.692C76.4,158.174,101.389,142.507,133,130.562a237.185,237.185,0,0,1,29.937-8.406,283.246,283.246,0,0,0,27.913-7.566,138.158,138.158,0,0,0,61.729-42.383,102.647,102.647,0,0,0,17.912-33.719c37.011,27.92,56.863,75.56,60.415,122.311,2.077,27.441-.967,61.689-8.563,96.455C305.237,335.331,263.3,420.314,196.838,424.66Zm128.181-124.9c4.241-13.119,7.83-26.428,10.7-39.58.93-4.154,1.947-9.128,2.97-14.677a19.134,19.134,0,0,1,10.008,17.31c-.071,1.274-1.794,25.809-23.674,36.948Z"
          transform="translate(-13 0)"
          fill="#e9beb7"
        />
      </g>
      <g
        id="noun-skin-3858726-2"
        data-name="noun-skin-3858726"
        transform="translate(861.519 177.586)"
      >
        <path
          id="Path_72-2"
          data-name="Path 72"
          d="M78.813,34.07a6.851,6.851,0,1,0-1.92,13.567c25.474,3.6,70.205,18.38,83.323,76.928a6.934,6.934,0,0,0,8.195,5.171,6.83,6.83,0,0,0,5.181-8.178C158.106,52.48,102.14,37.361,78.813,34.07Z"
          transform="translate(127.663 82.457)"
        />
        <path
          id="Path_73-2"
          data-name="Path 73"
          d="M108.609,76.149a6.875,6.875,0,0,0-2.957-9.235,72.646,72.646,0,0,0-28.987-9.566c-24.685-3.041-40.386,15-41.038,15.761a6.871,6.871,0,0,0,.773,9.67,6.985,6.985,0,0,0,9.68-.769A35.124,35.124,0,0,1,75.016,70.944a60.308,60.308,0,0,1,24.351,8.135,6.869,6.869,0,0,0,9.242-2.93Z"
          transform="translate(37.914 138.238)"
        />
        <path
          id="Path_74-2"
          data-name="Path 74"
          d="M67.414,69.007H46.847a6.852,6.852,0,1,0,0,13.7H67.414a6.852,6.852,0,1,0,0-13.7Z"
          transform="translate(52.467 167.35)"
        />
        <path
          id="Path_75-2"
          data-name="Path 75"
          d="M104.692,57.271a72.616,72.616,0,0,0-28.986,9.566A6.848,6.848,0,1,0,82,79a60.231,60.231,0,0,1,24.334-8.135A34.969,34.969,0,0,1,135.3,81.956a6.939,6.939,0,0,0,9.643.746,6.869,6.869,0,0,0,.789-9.67,49.05,49.05,0,0,0-41.045-15.761Z"
          transform="translate(130.088 138.315)"
        />
        <path
          id="Path_76-2"
          data-name="Path 76"
          d="M105.406,69.007H84.851a6.852,6.852,0,0,0,0,13.7h20.554a6.852,6.852,0,1,0,0-13.7Z"
          transform="translate(144.634 167.35)"
        />
        <path
          id="Path_77-2"
          data-name="Path 77"
          d="M115.654,98.006h-54.8a6.855,6.855,0,0,0,0,13.71h54.8a6.855,6.855,0,0,0,0-13.71Z"
          transform="translate(86.423 237.676)"
        />
        <path
          id="Path_78-2"
          data-name="Path 78"
          d="M66.851,103.711H80.545a6.852,6.852,0,1,0,0-13.7H66.851a6.852,6.852,0,0,0,0,13.7Z"
          transform="translate(100.981 218.277)"
        />
        <path
          id="Path_79-2"
          data-name="Path 79"
          d="M80.544,106.007H66.85a6.85,6.85,0,0,0,0,13.7H80.544a6.85,6.85,0,1,0,0-13.7Z"
          transform="translate(100.982 257.08)"
        />
        <path
          id="Path_80-2"
          data-name="Path 80"
          d="M341.022,231.393a328.512,328.512,0,0,0,3.556-71.64C338.721,82.832,291.709,3.642,199.933.15c-5.352-.194-18.55-.194-23.611-.033C83.98,3.1,36.671,82.45,30.784,159.753A328.576,328.576,0,0,0,34.333,231.4,33.948,33.948,0,0,0,13,263.494,58.146,58.146,0,0,0,56.2,316.449c23.782,63.241,64.248,118.3,121.679,121.9,1.4.084,18.249.084,19.878-.013,57.294-3.763,97.653-58.749,121.4-121.89,32.2-8.409,42.483-37.379,43.205-53.213A33.792,33.792,0,0,0,341.022,231.393ZM44.449,160.8C49.891,89.588,92.916,16.513,176.772,13.81c7.529-.234,15.132-.247,22.661.03a125.212,125.212,0,0,1,59.14,16.724,89.748,89.748,0,0,1-16.638,33.01,124.292,124.292,0,0,1-55.548,38.061,271.663,271.663,0,0,1-26.568,7.185,251.9,251.9,0,0,0-31.679,8.917c-29.9,11.309-60.712,27.849-83.19,71.881-.284.569-.883.151-.91-.582a256.275,256.275,0,0,1,.408-28.237ZM26.671,263.105a19.425,19.425,0,0,1,10.008-17.587c1.017,5.546,2.033,10.51,2.963,14.664,2.863,13.152,6.456,26.461,10.7,39.58C28.454,288.63,26.742,264.142,26.671,263.105ZM196.838,424.662c-.562.03-17.6.037-18.1.013-66.566-4.175-108.607-89.22-125.73-167.42-2.649-12.041-4.689-24.013-6.191-35.673l.024-.044s6.559-18.35,10.305-25.692C76.4,158.174,101.389,142.507,133,130.562a237.185,237.185,0,0,1,29.937-8.406,283.246,283.246,0,0,0,27.913-7.566,138.158,138.158,0,0,0,61.729-42.383,102.647,102.647,0,0,0,17.912-33.719c37.011,27.92,56.863,75.56,60.415,122.311,2.077,27.441-.967,61.689-8.563,96.455C305.237,335.331,263.3,420.314,196.838,424.66Zm128.181-124.9c4.241-13.119,7.83-26.428,10.7-39.58.93-4.154,1.947-9.128,2.97-14.677a19.134,19.134,0,0,1,10.008,17.31c-.071,1.274-1.794,25.809-23.674,36.948Z"
          transform="translate(-13 0)"
        />
        <circle
          id="Ellipse_175"
          data-name="Ellipse 175"
          cx="6.849"
          cy="6.849"
          r="6.849"
          transform="translate(242.154 274.066)"
          fill="#ff5a5a"
        />
        <circle
          id="Ellipse_176"
          data-name="Ellipse 176"
          cx="6.852"
          cy="6.852"
          r="6.852"
          transform="translate(261.064 297.466)"
          fill="#ff5a5a"
        />
        <circle
          id="Ellipse_177"
          data-name="Ellipse 177"
          cx="6.849"
          cy="6.849"
          r="6.849"
          transform="translate(225.32 301.058)"
          fill="#ff5a5a"
        />
        <circle
          id="Ellipse_178"
          data-name="Ellipse 178"
          cx="6.849"
          cy="6.849"
          r="6.849"
          transform="translate(247.365 331.883)"
          fill="#ff5a5a"
        />
        <ellipse
          id="Ellipse_179"
          data-name="Ellipse 179"
          cx="6.849"
          cy="6.849"
          rx="6.849"
          ry="6.849"
          transform="translate(96.202 277.877)"
          fill="#ff5a5a"
        />
        <ellipse
          id="Ellipse_180"
          data-name="Ellipse 180"
          cx="6.852"
          cy="6.852"
          rx="6.852"
          ry="6.852"
          transform="translate(77.286 301.276)"
          fill="#ff5a5a"
        />
        <ellipse
          id="Ellipse_181"
          data-name="Ellipse 181"
          cx="6.849"
          cy="6.849"
          rx="6.849"
          ry="6.849"
          transform="translate(113.034 304.869)"
          fill="#ff5a5a"
        />
        <ellipse
          id="Ellipse_182"
          data-name="Ellipse 182"
          cx="6.849"
          cy="6.849"
          rx="6.849"
          ry="6.849"
          transform="translate(90.99 335.693)"
          fill="#ff5a5a"
        />
      </g>
    </g>
  </svg>
)
