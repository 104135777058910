import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from '@vtex/store-ui'

import { results } from './results'
import styles from './styles.json'

interface ResultProps {
  result: {
    faixaEtaria?: string
    alergias?: string
    coceira?: string
    irritacao?: string
    peleVermelha?: string
  }
  data: Data[]
}

type Data = {
  name: string
  props: {
    allItems: [
      {
        alt: string
        sources: [
          {
            srcSet: string
          }
        ]
      }
    ]
  }
}

const Result = ({ result, data }: ResultProps) => {
  const [productId, setProductId] = useState<
    | '159917658'
    | '194489809'
    | '115630261'
    | '115630265'
    | '115630257'
    | '115630253'
    | '194489389'
    | '115630277'
    | ''
  >('')

  const [age, setAge] = useState(1)
  const [modelImage, setModelImage] =
    useState<{ alt: string; sources: Array<{ srcSet: string }> }>()

  const [productImage, setProductImage] =
    useState<{ alt: string; sources: Array<{ srcSet: string }> }>()

  useEffect(() => {
    fetch(`
      /api/io/safedata/RW/search?_where=alergias=${result.alergias} AND coceira=${result.coceira} AND faixa_etaria=${result.faixaEtaria} AND irritacao=${result.irritacao} AND pele_vermelha=${result.peleVermelha}&_fields=produto,faixa_etaria`)
      .then((res) => res.json())
      .then((res) => {
        setProductId(res[0].produto)
        setAge(res[0].faixa_etaria)
      })
  }, [
    result.alergias,
    result.coceira,
    result.faixaEtaria,
    result.irritacao,
    result.peleVermelha,
  ])

  useEffect(() => {
    data[2].props.allItems.forEach((item, index) => {
      if (index === age - 1) {
        setModelImage(item)
      }
    })

    if (productId !== '') {
      data[3].props.allItems.forEach((item) => {
        if (item.alt === results[productId]?.productName) {
          setProductImage(item)
        }
      })
    }
  }, [age, data, productId])

  if (productId === '') {
    return (
      <Flex sx={{ flexGrow: ['1'] }}>
        <Center>
          <Spinner />
        </Center>
      </Flex>
    )
  }

  return (
    <Flex sx={styles}>
      <Image
        alt={modelImage?.alt}
        src={modelImage?.sources[0].srcSet}
        sx={styles.modelImage}
      />
      <Flex sx={styles.resultWrapper}>
        <Box sx={styles.resultWrapper.heading}>
          <Text as="p">Passo 7 de 7</Text>
          <Heading>Resultado</Heading>
        </Box>

        <Flex sx={styles.result}>
          <Image
            alt={productImage?.alt}
            src={productImage?.sources[0].srcSet}
          />
          <Flex>
            <Heading
              as="h1"
              sx={{ backgroundColor: results[productId]?.color }}
            >
              {results[productId]?.productName}
            </Heading>
            <Box sx={styles.result.benefits}>
              {results[productId]?.benefits.map((benefit) => (
                <Heading as="h4" key={benefit}>
                  {benefit}
                </Heading>
              ))}
            </Box>

            <Text sx={styles.text}>
              {results[productId]?.text.map((text) => (
                <p key={text}>{text}</p>
              ))}
            </Text>

            <Link href={results[productId]?.link}>Eu Quero</Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Result
