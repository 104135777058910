import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Label,
  Text,
} from '@vtex/store-ui'
import { isValidEmail } from 'src/utils'
import { useProfile, useToast } from '@vtex/gatsby-theme-store'

import styles from './styles.json'
import type { QuestionsType } from '../typings'

export const First = ({ question, nextQuestion, image }: QuestionsType) => {
  const [checked, setChecked] = useState(false)
  const [email, setEmail] = useState('')
  const [emailUserActive, setEmailUserActive] = useState(false)
  const [name, setName] = useState('')
  const [documentId, setDocumentId] = useState('')
  const inputEmail = useRef<HTMLInputElement | null>(null)
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const { showToast } = useToast()

  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = profile?.email?.value
      const requestUrl = `/api/io/safedata/CL/search?_where=email=${userEmail}&_fields=id`
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }

      if (userEmail && inputEmail?.current) {
        inputEmail.current.value = userEmail
        setEmailUserActive(true)
        setEmail(userEmail)

        fetch(requestUrl, options)
          .then((res) => res.json())
          .then((data) => {
            setDocumentId(data[0].id)
          })
          .catch((error) => console.error('Error while get data', error))
      }
    }
  }, [documentId, isAuthenticated, profile?.email?.value])

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()

    const response = {
      email,
      name,
    }

    if (isValidEmail(email) && !checked) {
      nextQuestion(response)
    }

    if (isValidEmail(email) && checked) {
      const optionsAuthenticated = {
        method: 'PATCH',
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailCliente: email,
          nomeCliente: name,
        }),
      }

      const optionsUnauthenticated = {
        method: 'POST',
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailCliente: email,
          nomeCliente: name,
        }),
      }

      if (documentId) {
        const requestUrl = documentId
          ? `/api/io/safedata/RQ/documents/${documentId}`
          : `/api/io/safedata/RQ/documents`

        fetch(requestUrl, optionsAuthenticated)
          .then((res) => res.json())
          .then(() => nextQuestion(response))
          .catch((error) => console.error('Error while fetch data', error))
      } else {
        fetch(`/api/io/safedata/RQ/documents`, optionsUnauthenticated)
          .then((res) => res.json())
          .then(() => nextQuestion(response))
          .catch((error) => console.error('Error while fetch data', error))
      }
    }

    if (!isValidEmail(email)) {
      showToast({
        content: 'Email inválido. Digite um email válido',
        type: 'error',
        id: 'mobile-add-to-cart',
      })
    }
  }

  return (
    <Grid sx={styles}>
      <Image
        alt={image?.alt}
        src={image?.desktop.srcSet}
        title={image?.title}
      />
      <Flex sx={styles.formWrapper}>
        <Text sx={styles.formWrapper.step} as="p">
          Passo {question.step} de 7
        </Text>
        <Heading>{question.question}</Heading>
        <Text sx={styles.formWrapper.description} as="p">
          {question.description}
        </Text>

        <Flex as="form" onSubmit={(e) => handleSubmit(e)} sx={styles.form}>
          <Input
            type="email"
            placeholder="E-mail"
            ref={inputEmail}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!emailUserActive}
            required
          />
          <Input
            type="text"
            placeholder="Nome"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Label sx={styles.form.checkbox}>
            <Checkbox onClick={() => setChecked(!checked)} required />
            Aceito os termos de política de privacidade
          </Label>
          <Button type="submit">Próximo</Button>
        </Flex>
      </Flex>
    </Grid>
  )
}
