import React from 'react'
import { Box, Flex, Heading, Image, Text } from '@vtex/store-ui'

import styles from './styles.json'
import type { QuestionsType } from '../typings'

const Second = ({ question, nextQuestion, image }: QuestionsType) => {
  const handleClick = (selectedOption: string) => {
    switch (selectedOption) {
      case '18+':
        nextQuestion({ faixaEtaria: '1' })
        break

      case '30+':
        nextQuestion({ faixaEtaria: '2' })
        break

      case '45+':
        nextQuestion({ faixaEtaria: '3' })
        break

      case '55+':
        nextQuestion({ faixaEtaria: '4' })
        break

      default:
        break
    }
  }

  return (
    <Flex sx={styles}>
      <Text sx={styles.step} as="p">
        Passo {question.step} de 7
      </Text>
      <Heading>{question.question}</Heading>
      <Flex sx={styles.options}>
        {image?.allItems.map((img) => (
          <Flex key={img.alt} onClick={() => handleClick(img.alt)}>
            <Box>
              <Image alt={img.alt} src={img.sources[0].srcSet} />
            </Box>
            <Text as="p">{img.alt}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default Second
