import React, { useState } from 'react'
import { Box, SuspenseSSR } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import Header from 'src/components/quiz-antissinais/Header'
import Questions from 'src/components/quiz-antissinais/Questions'

interface Props {
  data: {
    cmsInstitutionalPage: {
      sections: []
    }
  }
}

const AboveTheFold = ({ data: { cmsInstitutionalPage } }: Props) => {
  const [step, setStep] = useState(0)

  return (
    <Box
      sx={{
        background: [
          'linear-gradient(0deg, #F4E0DD 0%, #FFFFFF 100%)',
          'linear-gradient(90deg, #FFEFF9 0%, #FFFFFF 100%)',
        ],
        fontFamily: 'Zona Pro, sans-serif',
      }}
    >
      <EventPageView
        type="OTHER"
        title={cmsInstitutionalPage.seo.siteMetadataWithSlug.title}
      />
      <Header step={step} setStep={setStep} />
      <SuspenseSSR fallback={null}>
        <Questions
          step={step}
          setStep={setStep}
          data={cmsInstitutionalPage.sections}
        />
      </SuspenseSSR>
    </Box>
  )
}

export default AboveTheFold
