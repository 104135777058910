export const questions = [
  {
    step: 1,
    question: 'Qual o seu nome?',
    description:
      'Antes de iniciar o teste, conta pra gente o seu nome e o seu e-mail?',
  },
  {
    step: 2,
    question: 'Qual a sua faixa etária?',
    options: [
      { option: '18+' },
      { option: '30+' },
      { option: '45+' },
      { option: '55+' },
    ],
  },
  {
    step: 3,
    question:
      'Com que frequência você costuma ter coceira, vermelhidão, ardência e/ou hiperpigmentação na pele do rosto?',
    options: [
      { option: 'Sempre' },
      { option: 'Às vezes' },
      { option: 'Nunca' },
    ],
  },
  {
    step: 4,
    question: 'Você já teve alguma irritação na pele ao usar cosméticos?',
    options: [{ option: 'Sim' }, { option: 'Não' }],
  },
  {
    step: 5,
    question: 'Quando toma banho quente sua pele fica vermelha e ressecada?',
    options: [{ option: 'Sim' }, { option: 'Não' }],
  },
  {
    step: 6,
    question: 'Você costuma ter alergias na pele do rosto?',
    options: [{ option: 'Sim' }, { option: 'Não' }],
  },
]
